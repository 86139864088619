import React from "react";
import { Save, ArrowUp, AtSign } from "lucide-react";
import Speeddial from "./ui/SpeedDial";
import whatsapp from "./../assets/whatsapp.png";

const SpeedDailDemo = () => {
    const actionButtons = [
        {
            icon: <img src={whatsapp} alt="WhatsApp" className="size-6" />,
            label: "Contact",
            key: "contact",
            action: () => {
                const phoneNumber = "+919740026068"; // Replace with the desired phone number
                const message = "Hello!"; // Replace with your default message
                const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
                window.open(whatsappUrl, "_blank");
            },
        },
        {
            icon: <AtSign size={20}/>,
            label: "Email",
            key: "email",
            action: () => {
                window.location.href = "mailto:parnetsale@gmail.com";
            },
        },
        {
            icon: <ArrowUp size={20} />,
            label: "Top",
            key: "goToTop",
            action: () => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Adds smooth scrolling
                });
            },
        },
    ];

    return (
        <div className="flex items-center justify-center">
            <Speeddial direction="up" actionButtons={actionButtons} />
        </div>
    );
};

export default SpeedDailDemo;
