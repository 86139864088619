import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "sonner";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FloatingDockDemo from "./components/FloatingDockDemo";
import SpeedDailDemo from "./components/SpeedDailDemo";
import PolicyIconsDemo from "./components/PolicyIconsDemo";
import ParNetsLoader from "./components/ParnetsLoader";

// Lazy loaded components
const LandingPage = React.lazy(() => import("./components/LandingPage"));
const Products = React.lazy(() => import("./components/Products"));
const Home2 = React.lazy(() => import("./components/Home2"));
const Service = React.lazy(() => import("./components/Service"));
const Projects = React.lazy(() => import("./components/Projects"));
const Career = React.lazy(() => import("./components/Career"));
const Contact = React.lazy(() => import("./components/Contact"));
const Industries = React.lazy(() => import("./components/Industries"));
const JoinUs = React.lazy(() => import("./components/JoinUs"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const MaintenancePolicy = React.lazy(() => import("./components/MaintenancePolicy"));
const TermsAndCondition = React.lazy(() => import("./components/TermsAndCondition"));
const GoogleForm = React.lazy(() => import("./components/GoogleForm"));

const App = () => {
  return (
    <BrowserRouter>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            padding: "16px",
            margin: "10px",
          },
        }}
      />
      <Header />
      <FloatingDockDemo />
      <Suspense fallback={<ParNetsLoader/>}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/about" element={<Home2 />} />
          <Route path="/service" element={<Service />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/joinUs" element={<JoinUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/maintenancepolicy" element={<MaintenancePolicy />} />
          <Route path="/termsandcondition" element={<TermsAndCondition />} />
          <Route path="/salesupdate" element={<GoogleForm />} />
        </Routes>
      </Suspense>
      <Footer />
      <SpeedDailDemo />
      <PolicyIconsDemo />
    </BrowserRouter>
  );
};

export default App;
