// import React from 'react';
// import banner from './../assets/background-1.jpg';

// const ParNetsLoader = ({ size = 100, speed = 1.5 }) => {
//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen  p-4"
//         style={{ backgroundImage: `url(${banner})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "auto", overflow: "hidden" }}
//     >
//       <div className="text-center mb-8">
//         <h1 className="text-5xl font-bold text-black mb-4 tracking-wide">
//           ParNets
//         </h1>
//         <p className="text-xl text-black opacity-75">
//           Connecting Networks, Empowering Insights
//         </p>
//       </div>
      
//       <div 
//         className="flex items-center justify-center"
//         style={{ width: size, height: size }}
//       >
//         <svg 
//           xmlns="http://www.w3.org/2000/svg" 
//           viewBox="0 0 200 200"
//           className="animate-spin"
//           style={{ animationDuration: `${speed}s` }}
//         >
//           {/* Outer Ring */}
//           <circle 
//             cx="100" 
//             cy="100" 
//             r="90" 
//             fill="none" 
//             stroke="black" 
//             strokeWidth="10"
//             strokeDasharray="280 280"
//             className="opacity-30"
//           />
          
//           {/* Dynamic Connecting Nodes */}
//           <circle 
//             cx="50" 
//             cy="50" 
//             r="15" 
//             fill="black" 
//             className="animate-pulse"
//           />
//           <circle 
//             cx="150" 
//             cy="50" 
//             r="15" 
//             fill="black" 
//             className="animate-pulse delay-500"
//           />
//           <circle 
//             cx="50" 
//             cy="150" 
//             r="15" 
//             fill="black" 
//             className="animate-pulse delay-1000"
//           />
//           <circle 
//             cx="150" 
//             cy="150" 
//             r="15" 
//             fill="black" 
//             className="animate-pulse delay-1500"
//           />
          
//           {/* Central Spinning Node */}
//           <circle 
//             cx="100" 
//             cy="100" 
//             r="25" 
//             fill="black"
//             className="origin-center"
//           />
//         </svg>
//       </div>
      
//       <div className="mt-8 text-center">
//         <p className="text-lg text-black opacity-75">
//           Loading your network insights...
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ParNetsLoader;




import Lottie from 'lottie-react';
import React from 'react';
import loder from '../assets/Animation - 1735544240874'
const ParNetsLoader = ({ size = 100, speed = 1.5 }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#E2F3ED]">
      <Lottie animationData={loder} className='w-[30%]'/>
      <h1 className='text-[#F04c27] text-3xl font-bold'>Loading...</h1>
       </div>
  );
};

export default ParNetsLoader;