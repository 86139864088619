import React from "react";
import { FloatingDock } from "@/components/ui/floating-dock";
import {
  IconBrandGithub,
  IconBrandX,
  IconBuilding,
  IconDeviceDesktopCheck,
  IconDeviceDesktopStar,
  IconDeviceLaptop,
  IconExchange,
  IconFileCertificate,
  IconHome,
  IconPhoneCalling,
} from "@tabler/icons-react";

export default function FloatingDockDemo() {
  const links = [
    {
      title: "Home",
      icon: (
        <IconHome className="w-full h-full lg:text-black dark:text-neutral-300" />
      ),
      href: "/",
    },
    {
      title: "About",
      icon: (
        <IconBuilding className="w-full h-full lg:text-black dark:text-neutral-300" />
      ),
      href: "/about",
    },
    {
      title: "Service",
      icon: (
        <IconDeviceDesktopStar className="w-full h-full lg:text-black dark:text-neutral-300" />
      ),
      href: "/service",
    },
    {
      title: "Projects",
      icon: (
        <IconDeviceDesktopCheck className="w-full h-full lg:text-black dark:text-neutral-300" />
      ),
      href: "/projects",
    },
    {
      title: "Industries",
      icon: (
        <IconDeviceLaptop className="w-full h-full lg:text-black dark:text-neutral-300" />
      ),
      href: "/industries",
    },
    {
      title: "Contact",
      icon: (
        <IconPhoneCalling className="w-full h-full lg:text-black dark:text-neutral-300" />
      ),
      href: "/contact",
    },
    {
      title: "Career",
      icon: (
        <IconFileCertificate className="w-full h-full lg:text-black dark:text-neutral-300" />
      ),
      href: "/career",
    },
  ];

  return (
    <div className="items-center justify-center w-full fl50ex z-">
      <FloatingDock
        className="fixed bottom-0 z-50 transform -translate-x-1/2 left-1/2 lg:bottom-5"
        items={links}
      />
    </div>
  );
}
