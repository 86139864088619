

import React from "react";
import { FileText,  Settings, Shield } from "lucide-react";
import { useNavigate } from "react-router-dom";

const PolicyIconsDemo = () => {
    const navigate = useNavigate();
    const policies = [
        {
            icon: <Shield size={24} />,
            label: "Privacy Policy",
            action: () => navigate('/privacypolicy'),
        },
        {
            icon: <Settings size={24} />,
            label: "Maintainence Policy",
            action: () => navigate('/maintenancepolicy'),
        },
        {
            icon: <FileText size={24} />,
            label: "Terms and Conditions",
            action: () => navigate('/termsandcondition'),
        },
    ];

    return (
        <div className="fixed z-50 flex flex-col space-y-4 lg:left-4 lg:bottom-12 bottom-20 left-2">
            {policies.map((policy, index) => (
                <div
                    key={index}
                    className="relative flex items-center justify-center w-12 h-12 transition bg-gray-100 rounded-full shadow-md cursor-pointer group hover:bg-gray-200"
                    onClick={policy.action}
                >
                    {/* Icon */}
                    <div>{policy.icon}</div>

                    {/* Label as Tooltip */}
                    <span className="absolute z-10 px-3 py-1 text-sm font-medium text-white transition-opacity duration-300 bg-gray-800 rounded shadow-lg opacity-0 pointer-events-none pootext-white left-14 group-hover:opacity-100">
                        {policy.label}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default PolicyIconsDemo;
